<template>
  <div class="content">
    <div class="main">
      <service-and-support-nav :active-index="3" />
      <service-and-support-list searchType @change="search_change" />
      <div class="mainView">
        <div class="hot_articles">
          <div class="title">
            <h4>十大熱門教學文章</h4>
          </div>
          <router-link
            :to="`/knowledge_base_detailed?id=${item.id}&pid=${item.f_cate_id}&twoId=${item.cate_id}`"
            class="item"
            v-for="(item, index) in hot_articles"
            :key="index"
            ><p>{{ item.title }}</p>
            <i class="el-icon-arrow-right icon"></i
          ></router-link>
        </div>
        <div class="teaching_film">
          <div class="title">
            <h4>教學影片</h4>
            <el-link icon="el-icon-refresh-right" @click="change_the_group"
              >換一組</el-link
            >
          </div>
          <div
            class="item"
            v-for="(item, index) in videoList"
            :key="index"
            @click="videoDialogFun(item)"
          >
            <div class="imgView">
              <img class="img" :src="item.pic" alt="" />
              <img class="v_b" src="@/assets/images/v_b.png" alt="" />
            </div>
            <div class="txtView">
              <h5 class="NotoSansTCMedium">{{ item.title }}</h5>
              <p>{{ item.description }}</p>
            </div>
          </div>
          <a href="http://www.bareiss.tv/" target="_blank" class="link"
            >更多教學影片<i class="el-icon-arrow-right"></i
          ></a>
        </div>
      </div>
    </div>
    <video-dialog
      :show="videoDialogShow"
      :data="videoDialogData"
      @close="videoDialogShow = false"
    />
  </div>
</template>

<script>
import ServiceAndSupportNav from "@/components/service-and-support/nav";
import ServiceAndSupportList from "@/components/service-and-support-list";
import videoDialog from "@/components/video-dialog";
export default {
  name: "KnowledgeBase",
  components: {
    ServiceAndSupportNav,
    ServiceAndSupportList,
    videoDialog,
  },
  data() {
    return {
      hot_articles: [],
      videoList: [],
      videoPage: 1,
      videoLastPage: 1,
      videoTotal: 0,
      videoDialogShow: false,
      videoDialogData: {},
    };
  },
  mounted() {
    this.teachingArticles();
    this.knowledgeBaseVideoFun();
  },
  methods: {
    /**
     * 搜索回调
     */
    search_change(e) {
      if (e != "") {
        this.$router.push(`/knowledge_base_list?keyword=${e}`);
      }
    },
    /**
     * 教学文章
     */
    teachingArticles() {
      this.$http
        .knowledgeBaseList({
          limit: 10,
          page: 1,
          is_hot: 1,
        })
        .then((res) => {
          if (res.code == 200) {
            this.hot_articles = res.data.data;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    /**
     * 教学影片换一组
     */
    change_the_group() {
      if (this.videoPage == this.videoLastPage) {
        this.videoPage = 1;
      } else {
        this.videoPage++;
      }
      this.knowledgeBaseVideoFun();
    },
    //教学影片
    knowledgeBaseVideoFun() {
      this.$http
        .knowledgeBaseList({
          limit: 10,
          page: this.videoPage,
          is_video: 1,
        })
        .then((res) => {
          if (res.code == 200) {
            this.videoList = res.data.data;
            this.videoTotal = res.data.total;
            this.videoLastPage = res.data.last_page;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    /**视频点击 */
    videoDialogFun(item) {
      this.videoDialogData = {
        videoUrl: item.video,
      };
      this.videoDialogShow = true;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.main {
  width: 1400px;
  margin: 0 auto;
  @include respond-to(max1439) {
    width: 95%;
  }
  .mainView {
    display: flex;
    justify-content: space-between;
    padding: 50px 0 100px;
    @include respond-to(lg) {
      flex-direction: column;
      padding: 30px 0 60px;
    }
    > * {
      width: calc(50% - 66px);
      @include respond-to(lg) {
        width: 100%;
      }
      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 30px;
        @include respond-to(lg) {
          padding-bottom: 20px;
        }
        h4 {
          font-size: 32px;
          color: #333;
          font-weight: normal;
          @include respond-to(lg) {
            font-size: 22px;
          }
        }
      }
    }
    .hot_articles {
      @include respond-to(lg) {
        margin-bottom: 20px;
      }
      .item {
        height: 50px;
        line-height: 50px;
        border-bottom: 1px solid #cfd4d8;
        box-sizing: border-box;
        font-size: 16px;
        color: #333;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:hover {
          color: #0075c1;
        }
        p {
          width: 90%;
          word-break: break-all;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
        }
      }
    }
    .teaching_film {
      .link {
        color: #333;
        font-size: 14px;
        display: inline-flex;
        align-items: center;
        &:hover {
          color: #0075c1;
        }
      }
      .item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 32px;
        cursor: pointer;
        .imgView {
          width: 160px;
          border-radius: 5px;
          overflow: hidden;
          position: relative;
          .img {
            display: block;
            width: 100%;
          }
          .v_b {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: block;
          }
        }
        .txtView {
          width: calc(100% - 180px);
          h5 {
            font-size: 16px;
            color: #1a1a1a;
            font-weight: normal;
            margin-bottom: 8px;
            @include respond-to(lg) {
              word-break: break-all;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
            }
          }
          p {
            font-size: 12px;
            color: #666;
            line-height: 1.6;
            @include respond-to(lg) {
              word-break: break-all;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              overflow: hidden;
            }
          }
        }
        &:hover {
          .txtView {
            h5 {
              color: #0075c1;
            }
          }
        }
      }
    }
  }
}
</style>